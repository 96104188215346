import {
  getItemDescendants,
  getItems,
  UpdateBasketMutation,
} from "@clearabee/ui-sdk";
import { UseAnalyticsReturn } from "lib/hooks/useAnalytics";
import { Basket } from "lib/types";

export const getCategoriesInBasket = <Item extends { sku: string }>(
  basketItems: Basket["items"],
  items: Item[],
): Item[] => {
  return (
    basketItems.reduce<Item[]>((final, curr) => {
      if (
        curr.parentSku &&
        curr.parentSku !== "SURCHARGES" &&
        curr.parentSku !== "SKIPBAGS" &&
        !final.find(({ sku }) => curr.parentSku === sku)
      ) {
        const item = getItems(curr.parentSku, items);
        if (item) final.push(item);
      }
      return final;
    }, []) || []
  );
};

export const handleRemoveItemsFromBasket = async (
  itemToRemoveSku: string,
  items: Basket["items"],
  update: UpdateBasketMutation,
  ecommerce: UseAnalyticsReturn["ecommerce"],
) => {
  const item = getItems(itemToRemoveSku, items || []);
  try {
    if (!item) {
      const children = getItemDescendants(itemToRemoveSku, items).map(
        ({ sku }) => sku,
      );
      const newItems = items.filter(({ sku }) => !children.includes(sku));

      await update.mutateAsync({
        items: newItems,
      });
      ecommerce.removeFromCart(newItems ?? []);
    } else if (item.parentSku) {
      const newItems = items.filter(({ sku }) => sku !== itemToRemoveSku);
      await update.mutateAsync({
        items: newItems,
      });
      ecommerce.removeFromCart(newItems ?? []);
    }
  } catch {}
};
