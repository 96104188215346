import React from "react";
import { CatalogueProvider } from "@clearabee/ui-sdk/dist/context/CatalogueProvider";
export { withCatalogueServerSideProps as getServerSideProps } from "lib/serverSideProps/withCatalogue";
import { CachedCatalogueServerSideProps } from "lib/serverSideProps/withCatalogue";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { useRouter } from "next/router";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { StoredCatalogues } from "@clearabee/ui-sdk/dist/context/CatalogueProvider";
import { Spinner } from "components/Core/Spinner/Spinner";
import { BasketPopupData } from "containers/BasketPopupData/BasketPopupData";
import { WithConditionalCSSProp } from "@emotion/react/types/jsx-namespace";
import { getEnv } from "lib/getEnv";

export const withServerSideCatalogue = <T,>(
  Page: React.ComponentType<T>,
): React.ComponentType<T & CachedCatalogueServerSideProps> => {
  return ({ initialStore: serverInitialStore, ...props }) => {
    const { query, isReady } = useRouter();
    const [translate] = useTranslation();
    const { postcode } = query;

    /**
     * Client side fallback query.
     * If an error is thrown server side,
     * have the client attempt to fetch the defaultCatalogue instead.
     */
    const { data: clientInitialStore, isError } = useQuery<StoredCatalogues>(
      ["clientSideGetCatalogue"],
      async () => {
        const res = await instance.catalogues.getCatalogue("hwa", {
          params: {
            flatten: true,
          },
        });
        const store: StoredCatalogues = {
          defaultCatalogue: res.data,
        };

        if (postcode && typeof postcode === "string") {
          const postcodeRes = postcode
            ? await instance.catalogues.getCatalogue("hwa", {
                params: {
                  flatten: true,
                  postcode,
                },
              })
            : undefined;
          store[postcode] = postcodeRes?.data;
        }

        return store;
      },
      {
        /**
         * Intentionally retry 3 times, using the default `back off` delay
         */
        enabled: !serverInitialStore && isReady,
        initialData: serverInitialStore,
      },
    );
    const initialStore = serverInitialStore || clientInitialStore;

    if (isError) {
      return (
        <ErrorPage
          errorTitle={translate("hwa.errors.errorOccurred")}
          headingSize="xlarge3"
          errorMessage={translate("hwa.errors.contactSupport")}
          linkText={translate("common.contactUs")}
          linkUrl={`${getEnv("WEBSITE_URL")}/contact`}
        />
      );
    }

    if (!initialStore) {
      return (
        <Spinner
          size="large"
          color="accent"
          style={{
            alignItems: "center",
            paddingTop: "35vh",
            paddingBottom: "40vh",
          }}
        />
      );
    }

    return (
      <CatalogueProvider initialStore={initialStore}>
        <Page
          {...(props as JSX.IntrinsicAttributes &
            WithConditionalCSSProp<React.PropsWithChildren<T>> &
            T & { children?: React.ReactNode })}
        />
        <BasketPopupData />
      </CatalogueProvider>
    );
  };
};
