import { instance } from "@clearabee/ui-sdk/dist/api/instance";
import { GetServerSidePropsContext } from "next";
import {
  FlatCatalogue,
  StoredCatalogues,
} from "@clearabee/ui-sdk/dist/context/CatalogueProvider";

export type CachedCatalogueServerSideProps =
  | { initialStore: StoredCatalogues }
  | Record<string, undefined>;

export const withCatalogueServerSideProps = async (
  context: GetServerSidePropsContext,
): Promise<{ props: CachedCatalogueServerSideProps }> => {
  try {
    const params = new URL(
      context.req.url ?? "",
      `http://${context.req.headers.host}`,
    ).searchParams;

    const defaultCatalogue: FlatCatalogue = (
      await instance.catalogues.getCatalogue("hwa", {
        params: { flatten: true },
      })
    ).data;

    let postcode = params.get("postcode");
    const { basketToken } = context.req.cookies;
    if (!postcode && basketToken) {
      const res = await instance.catalogues.getBasket(basketToken);
      postcode = res.data.deliveryAddress?.postcode || "";
    }

    const postcodeCatalogue = postcode
      ? (
          await instance.catalogues.getCatalogue("hwa", {
            params: { postcode, flatten: true },
          })
        ).data
      : undefined;
    return {
      props: {
        initialStore: postcode
          ? {
              [postcode]: postcodeCatalogue,
              defaultCatalogue,
            }
          : { defaultCatalogue },
      },
    };
  } catch (err) {
    console.error(err);
    return {
      props: {},
    };
  }
};
