import { theme } from "@clearabee/ui-library-native";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  wrap: {
    width: "100%",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "center",
    flex: 1,
    marginVertical: theme.spacing.xlarge4,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  heading: {
    textAlign: "center",
    color: theme.colors.light.base,
  },
  text: {
    color: theme.colors.light.base,
    marginBottom: theme.spacing.medium,
  },
  rightSide: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  imageWrap: {
    paddingRight: theme.spacing.xlarge3,
    width: "100%",
    borderRightColor: theme.colors.accent.base,
    borderRightWidth: 2,
  },

  nativeWrap: {
    minHeight: "100%",
    backgroundColor: theme.colors.brand.base,
    display: "flex",
  },
  nativeContentWrap: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    marginTop: theme.spacing.xlarge4,
  },
  nativeHeading: {
    marginTop: theme.spacing.xlarge3,
  },
  nativeImageWrap: {
    width: "100%",
    aspectRatio: 2 / 1,
  },
  nativeImage: { width: "100%", height: "100%" },
  nativeButton: { marginTop: theme.spacing.medium },
});
