import React from "react";
import { View } from "react-native";
import { Heading, Text, useResponsive } from "@clearabee/ui-library-native";
import { Container } from "components/Container/Container";
import { styles } from "./ErrorPage.styles";
import helpDesk from "lib/images/help-desk.png";
import { ButtonLink } from "components/Core/ButtonLink/ButtonLink";
import { Image } from "components/Image/Image";
import { ErrorPageProps } from "./ErrorPage.types";
import { getEnv } from "lib/getEnv";

export const ErrorPage = ({
  errorTitle = "404",
  errorMessage = "This page cannot be found",
  linkText = "Back to home",
  headingSize = "xlarge5",
  linkUrl,
}: ErrorPageProps): React.ReactElement => {
  const {
    screens: { medium },
  } = useResponsive();

  return (
    <View style={styles.wrap}>
      <Container style={styles.container}>
        {medium && (
          <View style={styles.imageWrap}>
            <Image
              source={helpDesk}
              style={{ width: "100%", height: "100%" }}
              resizeMode="contain"
            />
          </View>
        )}
        <View style={styles.rightSide}>
          <Heading styles={styles.heading} fontSize={headingSize} level={1}>
            {errorTitle}
          </Heading>
          <Text styles={styles.text}>{errorMessage}</Text>

          <ButtonLink
            color="accent"
            size="medium"
            routeName="Redirect"
            params={{ url: linkUrl ?? getEnv("WEBSITE_URL") ?? "" }}
            newTab={!!linkUrl}
          >
            {linkText}
          </ButtonLink>
        </View>
      </Container>
    </View>
  );
};
