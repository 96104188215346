import React, { useEffect, useContext } from "react";
import {
  useBasket,
  useCatalogue,
  isSurcharge,
  isAdditionalCharge,
} from "@clearabee/ui-sdk";
import { getCategoriesInBasket } from "lib/helpers/basketHelper";
import { HeaderBasket } from "@clearabee/ui-library";
import placeholderImage from "lib/images/placeholder.png";
import { WebLayoutContext } from "containers/WebLayout/WebLayout";
import { belongsToSkipBags, getCategoryFromItems } from "lib/utils";

export const BasketPopupData = (): null => {
  const { basket } = useBasket();
  const { defaultCatalogue } = useCatalogue();
  const { setItems } = useContext(WebLayoutContext);

  useEffect(() => {
    const services = getCategoriesInBasket(
      basket?.items || [],
      defaultCatalogue?.items || [],
    ).filter(({ sku }) => !isAdditionalCharge(sku) && !isSurcharge(sku));

    const data: HeaderBasket["items"] = services.map(({ sku, img, title }) => {
      return {
        sku,
        img: img ?? placeholderImage,
        title: belongsToSkipBags(sku)
          ? getCategoryFromItems(defaultCatalogue, [{ sku }])?.title
          : title,
        lineCost: basket?.totalCost,
        isRemovable: true,
        link: "/basket/edit",
      };
    });
    setItems(data);
  }, [basket]);

  return null;
};
