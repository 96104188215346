import React from "react";
import NextImage from "next/image";
import { View } from "react-native";
import { ImageProps } from "./types";

export const Image = ({
  source,
  resizeMode = "cover",
  style,
  dataSet,
  objectPosition,
  alt,
}: ImageProps): React.ReactElement => {
  return (
    <View style={style} dataSet={dataSet}>
      <NextImage
        src={source as string}
        alt={alt ?? ""}
        fill={true}
        style={{ objectFit: resizeMode as any, objectPosition }}
      />
    </View>
  );
};
